import React from "react";
import { Link } from "react-router-dom";

import { Stack, Typography } from "@mui/material";

import { DefaultButton } from "~common/components/controls/buttons";

const data = [
  {
    section: "Misc",
    pages: [
      { name: "Root", url: "/" },
      { name: "Gift Landing", url: "/g/123456" },
      { name: "Referral Landing", url: "/r/123456" },
      { name: "Campaign Landing", url: "/e/123456" },
      { name: "Incorrect URL", url: "/blahblah" },
      { name: "QuickStart", url: "/internal/quick" },
    ],
  },
  {
    section: "Auth",
    pages: [
      { name: "Sign In", url: "/sign-in" },
      { name: "Answer OTP", url: "/sign-in/answer-otp" },
      { name: "Complete Account", url: "/sign-in/complete-account" },
      { name: "Verify Email", url: "/sign-in/verify-email" },
      { name: "Confirm Email Code", url: "/verify/email/123456" },
    ],
  },
  {
    section: "Main",
    pages: [
      { name: "Home Tab", url: "/home" },
      { name: "Account Tab", url: "/account" },
      { name: "Shop Tab", url: "/shop" },
    ],
  },
];

const QuickStart: React.VFC = () => (
  <Stack>
    <Stack
      direction="row"
      spacing={8}
      sx={{
        maxWidth: 960,
        margin: "0 auto",
        p: 8,
      }}
    >
      {data.map((section) => (
        <Stack
          key={section.section}
          spacing="-1px"
          sx={{
            maxWidth: 400,
          }}
        >
          <Typography variant="h2" sx={{ mb: 4 }}>
            {section.section}
          </Typography>
          {section.pages.map((link) => (
            <Link key={link.url} to={link.url}>
              <DefaultButton
                sx={{
                  width: "100%",
                }}
              >
                {link.name}
              </DefaultButton>
            </Link>
          ))}
        </Stack>
      ))}
    </Stack>
  </Stack>
);
export default QuickStart;
